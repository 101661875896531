import React from "react"
import { Link } from "gatsby"
import "./index.scss"
import { BsChevronCompactRight } from "react-icons/bs"
const NavCard = ({ navCard }) => {
  return (
    <div id="NavCard" className="NavCard p-0 m-0" data-testid="NavCard">
      {navCard && (
        <ul className="no-bullets p-0 m-0">
          {navCard &&
            navCard.map((item, index) => {
              const { header, footer } = item
              return (
                <li key={index}>
                  <Link to={header.to} data-testid={index}>
                    <div className="d-flex flex-row ">
                      <div className="imageContainer ">
                        <img
                          alt={header.alt}
                          title={header.title}
                          src={header.icon}
                          className="p-0 m-0"
                        />
                      </div>
                      <div className="both  pt-2">
                        <p className="title m-0">
                          {" "}
                          {header.title}
                          <BsChevronCompactRight size={10} />
                        </p>
                        <span className="description">{header.subtitle}</span>
                      </div>
                    </div>
                  </Link>
                  {footer && (
                    <div className="  row  py-2 px-0 mx-2">
                      {footer.map((bottomItem, index) => {
                        return (
                          <div className="col-lg-4 col-md-4" key={index}>
                            {bottomItem.link.map((footerLink, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={footerLink.to}
                                  className="footer-link"
                                >
                                  <ul className="pl-0">
                                    <li className="linkbottomx text-start   ">
                                      <div className=" bottomSection  p-0  ">
                                        {footerLink.description} {">"}
                                      </div>
                                    </li>
                                  </ul>
                                </Link>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </li>
              )
            })}
        </ul>
      )}
    </div>
  )
}
export default NavCard
