import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const BottomFooter = ({ __logo }) => {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(bottom-footer)/" }) {
          frontmatter {
            companyName
            title1
            title2
            title3
          }
        }
      }
    `)
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    frontmatter = __logo
  }

  return (
    <div
      className="row justify-content-between border-top py-3 bottomFooter"
      data-testid="BottomFooter"
    >
      <div className="col-12 col-md-6 text-center text-md-start">
        &copy; {new Date().getFullYear()}{" "}
        <span>{frontmatter?.companyName}</span>
      </div>
      <div className="col-12 col-md-6 text-center text-md-end">
        <Link to="/privacy-policy/">
          <span className="link">{frontmatter?.title1}</span>
        </Link>

        <Link to="/eula/">
          <span className="link">{frontmatter?.title2}</span>
        </Link>
        <Link to="/terms-of-use/">
          <span className="link">{frontmatter?.title3}</span>
        </Link>
      </div>
    </div>
  )
}

export default BottomFooter
