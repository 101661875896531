import { Link } from "gatsby"
import React from "react"
import { MdKeyboardArrowDown } from "react-icons/md"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const MidFooter = ({ __data }) => {
  let info = []
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(mid-footer)/" }) {
          exports {
            MidFooterData {
              Title
              Lien {
                description
                to
              }
            }
          }
        }
      }
    `)
    info = data.mdx.exports.MidFooterData
  } catch (error) {
    info = __data
  }

  return (
    <div
      className="  pb-4 pt-4 justify-content-center midFooter"
      data-testid="MidFooter"
    >
      {info?.map((item, index) => {
        return (
          <div className="  d-block d-sm-none " key={index}>
            <div className="row  border-top">
              <div className="col-6 text-start md-footer">
                <p className=" mb-3 mt-3 text-uppercase">{item.Title}</p>
              </div>

              <a
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls={`${item.Title}`}
                href={`#${item.Title}`}
                className="chevronRotation col-6 text-end"
              >
                <MdKeyboardArrowDown size={15} className="link mb-3 mt-3  " />
              </a>
            </div>
            <div id={`${item.Title}`} className="panel-collapse collapse in">
              {item.Lien.map((elt, i) => {
                return (
                  <Link to={elt.to} key={i}>
                    <ul className="pl-0 px-0 m-0 ">
                      <li className="link-footer text-start pb-2">
                        {elt.description}
                      </li>
                    </ul>
                  </Link>
                )
              })}
            </div>
          </div>
        )
      })}
      <div className="    d-none d-sm-block ">
        <div className=" row  ">
          {info?.map((item, index) => {
            return (
              <div className="col-3 md-footer" key={index}>
                <p className="fw-bold  mb-4 text-uppercase">{item.Title}</p>
                {item.Lien.map((elt, index) => {
                  return (
                    <Link to={elt.to} key={index}>
                      <ul className="pl-0 px-0 m-0  ">
                        <li className="link-footer text-start pb-2">
                          {elt.description}
                        </li>
                      </ul>
                    </Link>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MidFooter
