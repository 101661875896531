import React from "react"
import * as icon from "react-icons/ai"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./index.scss"

const TopFooter = ({ __logo }) => {
  let frontmatter = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(top-footer-t1)/" }) {
          frontmatter {
            logoWeb
            description
          }
        }
      }
    `)
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    frontmatter = __logo
  }

  return (
    <div className="row   topFooter" data-testid="TopFooter">
      <div className=" text-center text-md-start col-12 col-md-6 ">
        <Link to="/">
          <div>
            <img
              alt="MAXapps"
              title="MAXapps"
              src={frontmatter?.logoWeb}
              className="imgMaxapps"
            />
          </div>
        </Link>
        <div>
          <p className=" pt-2 ">{frontmatter?.description}</p>
        </div>
      </div>

      <div className=" col-12 col-md-6 text-center text-md-end ">
        {/* <a
          href="https://twitter.com/ma_xapps"
          target="_blank"
          rel="noopener noreferrer"
        >
          <icon.AiFillTwitterCircle size={30} />
        </a> */}
        <a
          href="https://www.linkedin.com/company/maxapps-mobile-solution-for-ibm-maximo/about/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <icon.AiFillLinkedin size={30} />
        </a>
        {/* <a
          href="https://www.facebook.com/MAXapsIBM/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <icon.AiFillFacebook size={30} />
        </a> */}
        <a
          href="https://www.youtube.com/channel/UCxgcwWJNjTKtT1pFaFO5_2w"
          target="_blank"
          rel="noopener noreferrer"
        >
          <icon.AiFillYoutube size={30} />
        </a>
      </div>
    </div>
  )
}

export default TopFooter
