import React from "react"
import Zendesk from "react-zendesk"

import { zendeskSetting, ZENDESK_KEY } from "./config"

function ZendeskButton() {
  return (
    <div className="col-12 col-lg-6 text-start">
      {/* <a href="/contact/">
                    <button className="buttonWhite">
                      <i className="bi bi-question-circle me-2"></i>Help{" "}
                    </button>
                  </a> */}
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...zendeskSetting}
        onLoaded={() => console.info("ZendeskButton is loaded")}
      />
    </div>
  )
}

export default ZendeskButton
