import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import NavCard from "../../home/nav-card"
// import PurpleButton from "../../home/buttons/purple-button"
// import YellowButton from "../../home/buttons/yellow-button"
import { readFromStorage } from "../../../services/utils"

import "./index.scss"
const NavBarDark = ({ _data, _logo }) => {
  const [clicked, setclicked] = useState(true)
  const [profile, setProfile] = useState(false)

  let contentNavBar = []
  let frontmatter = {}

  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(navbar-dark)/" }) {
          exports {
            data {
              column
              to
              navCard {
                header {
                  title
                  subtitle
                  alt
                  icon
                  to
                }
                footer {
                  link {
                    description
                    to
                  }
                }
              }
            }
          }
          frontmatter {
            logoWeb
            logoMobile
            buttonTitle1
            buttonTitle2
            buttonSize
          }
        }
      }
    `)
    contentNavBar = result.mdx.exports.data
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    contentNavBar = _data
    frontmatter = _logo
  }

  useEffect(() => {
    readFromStorage(({ profile }) => {
      setProfile(profile)
    })

    return () => {}
  }, [])

  return (
    <div id="NavBarDark" className="NavBarDark" data-testid="navbar">
      <nav
        className="navbar navbar-expand-lg navbar-light pb-3 "
        data-testid="navbarWeb"
      >
        <Link to="/" className="navbar-brand d-none d-md-block row">
          <img
            alt="MAXapps"
            title="MAXapps"
            src={`${frontmatter?.logoWeb}`}
            className="imgMaxapps"
          />
        </Link>
        <Link to="/" className="d-block d-md-none ">
          <img
            alt="MAXapps"
            title="MAXapps"
            src={frontmatter?.logoMobile}
            className="p-0 m-0 imgMaxappsMobile "
          />
        </Link>
        <div className=" d-block d-lg-none   d-flex align-items-center justify-content-center justify-content-md-end  col-7  ">
          <Link to="/contact/">
            <button className="btn2 " type="button">
              {frontmatter.buttonTitle1}
            </button>
          </Link>
          <Link to="/get-started/">
            <button className="btn " type="button">
              {frontmatter.buttonTitle2}
            </button>
          </Link>
        </div>
        <button
          className="navbar-toggler "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            setclicked(!clicked)
          }}
        >
          <span
            className={clicked ? "navbar-toggler-icon" : "bi bi-x-lg"}
          ></span>
        </button>
        <div className="collapse navbar-collapse d-none d-lg-block linkbottom">
          {contentNavBar && (
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {contentNavBar.map((item, index) => {
                return (
                  <li className="nav-item dropdown" key={index}>
                    <Link
                      to={item.to}
                      className="nav-link dropdown-toggle links"
                      id="navbarDropdown"
                      role="button"
                      // data-bs-toggle="dropdown"
                      // disabled="disabled"
                    >
                      {item.column}
                      {item.navCard !== null && (
                        <i className="bi bi-chevron-down navicon"></i>
                      )}
                    </Link>
                    {item.navCard !== null && (
                      <ul
                        className="dropdown-menu "
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <NavCard navCard={item.navCard} />
                        </li>
                      </ul>
                    )}
                  </li>
                )
              })}
            </ul>
          )}
          <Link to="/contact/">
            <button className="btn2 " type="button">
              {frontmatter.buttonTitle1}
            </button>
          </Link>
          <Link to="/get-started/">
            <button className="btn " type="button">
              {frontmatter.buttonTitle2}
            </button>
          </Link>
          {/* <PurpleButton
            data={frontmatter.buttonTitle2}
            size={frontmatter.buttonSize}
          />
          <YellowButton
            data={frontmatter.buttonTitle1}
            size={frontmatter.buttonSize}
            to={"/contact/"}
          /> */}
          {/* <input
            className="form-control "
            type="search"
            placeholder="Search"
            aria-label="Search"
          /> */}
        </div>
      </nav>
      <div id="sectionMobile " className=" d-block d-lg-none">
        <ul
          id="navbarSupportedContent"
          className="ulMobile panel-collapse collapse in"
        >
          <div className="row py-3 justify-content-start "></div>
          {contentNavBar.map((item, index) => {
            return (
              <div className="border-top linkbottom py-4" key={index}>
                <Link
                  to={item.to}
                  className="nav-link dropdown-toggle links"
                  id="navbarDropdown"
                  role="button"
                >
                  {item.column === "Login" ? (
                    profile ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          viewBox="0 0 299 198"
                        >
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path d="M284.3,0H14.7A14.71,14.71,0,0,0,0,14.7V183.3A14.71,14.71,0,0,0,14.7,198H284.3A14.71,14.71,0,0,0,299,183.3V14.7A14.71,14.71,0,0,0,284.3,0ZM287,183.3a2.7,2.7,0,0,1-2.7,2.7H14.7a2.7,2.7,0,0,1-2.7-2.7V14.7A2.7,2.7,0,0,1,14.7,12H284.3a2.7,2.7,0,0,1,2.7,2.7Z" />
                              <path d="M106.52,87.81,54.12,52.44a3.31,3.31,0,0,0-.86-.33,3.25,3.25,0,0,0-.59-.07,2.7,2.7,0,0,0-2.75,2.76V76a4.77,4.77,0,0,0,1.51,3.8,15.82,15.82,0,0,0,2.69,2L77,96.06,54.12,110.47a20.67,20.67,0,0,0-2.69,1.84,4.53,4.53,0,0,0-1.51,3.8v21.22a2.7,2.7,0,0,0,2.75,2.75,2.55,2.55,0,0,0,.59-.07,3.62,3.62,0,0,0,.86-.32l52.4-35.37a13.26,13.26,0,0,0,2.81-2.3,4.67,4.67,0,0,0,1-3.21v-5.5a4.69,4.69,0,0,0-1-3.21A13.25,13.25,0,0,0,106.52,87.81Z" />
                              <path d="M158.91,135.22H117.56a1.84,1.84,0,0,0-1.84,1.84v9.71a1.84,1.84,0,0,0,1.84,1.84h41.35a1.86,1.86,0,0,0,1.85-1.84v-9.71a1.86,1.86,0,0,0-1.85-1.84Z" />
                            </g>
                          </g>
                        </svg>
                        Console
                      </>
                    ) : (
                      "Login"
                    )
                  ) : (
                    item.column
                  )}
                </Link>

                <NavCard navCard={item.navCard} />
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default NavBarDark
