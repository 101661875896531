import React from "react"
import HeaderNavDark from "./header-nav"
import NavBarDark from "./nav-bar-dark"

const HeaderMain = () => {
  return (
    <div>
      <HeaderNavDark />
      <NavBarDark />
    </div>
  )
}

export default HeaderMain
