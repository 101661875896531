export const ZENDESK_KEY = "8923d2e1-aa09-4817-aaa1-95b865b01d0f"

export const zendeskSetting = {
  webWidget: {
    color: {
      theme: "#6b50a1",
    },
    launcher: {
      chatLabel: {
        "*": "Help!!!",
      },
    },
    chat: {
      title: {
        "*": "Contact us",
      },
      departments: {
        enabled: ["finance", "hr", "sales"],
      },
    },
    helpCenter: {
      messageButton: {
        "*": "Contact us now.",
      },
    },

    navigation: {
      popoutButton: {
        enabled: false,
      },
    },
    contactForm: {
      fields: [{ id: "description", prefill: { "*": "" } }],
      attachments: true,
    },
    contactOptions: {
      enabled: true,
      chatLabelOnline: { "*": "Live Chat" },
      chatLabelOffline: { "*": "Chat is unavailable" },
    },
  },
}
