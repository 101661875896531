import React from "react"
import MidFooter from "./mid-footer"
import BottomFooter from "./bottom-footer"
import TopFooter from "./top-footer"
import "./index.scss"

const FooterMain = () => {
  return (
    <footer className="pt-5">
      <div className="container-fluid p-0 m-0 footerMain">
        <TopFooter />
        <MidFooter />
        <BottomFooter />
      </div>
    </footer>
  )
}

export default FooterMain
